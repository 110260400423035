/* variables */
:root {
  --color-primary: #f0eff4;
  --color-secondary: #a63d40;
  --color-tertiary: #231942;
  --color-fourth: #90a959;
  --color-fifth: #6494aa;
}


/* base styles */
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: var(--color-primary);
}

body {
  font-family: Poppins, sans-serif;
  margin: 0;
  background: var(--color-primary);
}
ul, li, p, h1, h2, h3, h4 {
  margin: 1em 0;
  padding: 0;
  font-weight: 600;
}

h1{font-size: 5em;}

ul {
  list-style-type: none;
}

a{
  text-decoration: none;
}
a :hover {
  font-weight: 700;
}

/* layout */
.btn {
  background-color: var(--color-secondary);
  padding: 5px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  border: 1px solid var(--primary-color);
  align-self: center;
}

.btn:hover {
  color: var(--primary-color);
  background: inherit;
  border: .9px solid var(--primary-color);
  font-weight: 600;
}

/* table */
table {
  background-color: var(--color-fourth);
  border-radius: 3px;
}
th {
  font-weight: 600;
}

/* forms */
label {
  display: block;
  margin: 24px auto;
}
label span {
  display: block;
  margin-bottom: 6px;
}

img{ 
  object-fit: cover;
}

@media screen and (max-width: 1000px) {
  h1{
    font-size: 3em;
  } 
}