nav > ul{
    position: fixed;
    right: 0;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-right: 1em;
}

nav > ul > li {
    font-weight: 500;
    margin: 1em;
}

nav > ul > li > a > button {
    background-color: rgb(1,1,1,0);
    border: none;
    font-size: large;
}

.active {
    font-weight: 600;
}