.main-poster{
    height: 100vh;
    position: relative;
}

.main-poster > .logo {
    padding: 1em;
}

.mainImage{
    width: 100vw;
    height: 100vh;
    display: block;
    position: absolute;
    top: 0;
    z-index: -1;
    margin: auto;
}

.centrePiece{
    position: absolute;
    display: block;
    width: 100vw;
    text-align: center;
    bottom: 0;
    margin-bottom: 1em;
}

@media screen and (max-width: 1000px) {
    .gradText > h2{
      padding-top: 3em;
      font-weight: 500;
    }
    .gradText > h3{
      font-weight: 400;
    }
  }